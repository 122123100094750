import * as React from 'react';

export const Phone: React.FC<React.SVGProps<SVGSVGElement>> = ({
  ...svgProps
}) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.18864 0.0966305C2.45122 -0.0625891 2.78858 -0.0218587 3.00572 0.195276L5.47238 2.66194C5.70972 2.89928 5.7336 3.27606 5.52813 3.54146C4.74292 4.5557 4.63997 5.05265 4.71766 5.39358C4.76023 5.58046 4.87314 5.79998 5.11609 6.08702C5.36098 6.37635 5.70596 6.69766 6.16817 7.09384C6.19237 7.11458 6.21505 7.13703 6.23603 7.16101C6.36961 7.31368 6.49154 7.36525 6.60569 7.38248C6.73755 7.40239 6.91233 7.38298 7.1403 7.30368C7.5246 7.17001 7.89934 6.93213 8.28239 6.68897C8.38256 6.62539 8.48329 6.56145 8.58492 6.49891C8.85074 6.33533 9.19447 6.37807 9.41213 6.60178L11.8121 9.06844C12.0239 9.28609 12.0618 9.61935 11.9044 9.87902C11.3656 10.7676 10.672 11.3771 9.87058 11.7203C9.07348 12.0616 8.21453 12.1206 7.36884 11.9842C5.6948 11.7144 4.00472 10.672 2.68635 9.34486C1.36782 8.01757 0.33513 6.31897 0.0725248 4.63946C-0.0601107 3.79118 0.00188136 2.93047 0.344872 2.13246C0.689769 1.33002 1.29987 0.635567 2.18864 0.0966305ZM10.4748 9.60594C10.1308 10.0399 9.7466 10.3229 9.3457 10.4946C8.82379 10.7181 8.22805 10.7722 7.58104 10.6679C6.26966 10.4565 4.82224 9.60304 3.63228 8.40517C2.44247 7.20746 1.596 5.75189 1.38985 4.43349C1.28811 3.7828 1.34435 3.18362 1.56985 2.65897C1.74226 2.25783 2.02471 1.87409 2.45578 1.53096L4.12563 3.20081C3.53318 4.06518 3.22934 4.86337 3.41764 5.68978C3.52506 6.16124 3.77882 6.57089 4.09837 6.94842C4.40843 7.31476 4.80975 7.68438 5.26696 8.07744C5.59325 8.4342 5.98371 8.63704 6.40669 8.70088C6.82691 8.76431 7.2313 8.68372 7.57833 8.56301C8.02666 8.40707 8.47433 8.14477 8.83382 7.91939L10.4748 9.60594Z"
      fill="currentColor"
    />
  </svg>
);
