// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../public/harmony_bglogo_black.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1VwjFBah42iN3XrGr6AKAI {\n  background-size: initial;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat bottom right, linear-gradient(0deg, #0d0d0d, #0d0d0d), linear-gradient(0deg, rgba(33,53,75,0.15), rgba(33,53,75,0.15)), #111;\n  color: #f2f3f8;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  z-index: -1;\n}\n._34mGUfN-RAJLjrvXpzvTE5._34mGUfN-RAJLjrvXpzvTE5 {\n  min-width: 256px;\n}\n.RQpO_3G9W_j7kWPzxpYqK.RQpO_3G9W_j7kWPzxpYqK {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/components/Layouts/BaseLayout.styl"],"names":[],"mappings":"AACA;EAGE,wBAAiB;EACjB,0LAA0E;EAC1E,cAAO;EACP,eAAU;EACV,MAAK;EACL,OAAM;EACN,SAAQ;EACR,QAAO;EACP,WAAS;AAFX;AAKA;EACE,gBAAW;AAHb;AAMA;EACE,WAAO;AAJT","sourcesContent":["\n.bgImage {\n  //background-position: 0 100%;\n  //background-repeat: no-repeat;\n  background-size: initial;\n  background: url(\"/public/harmony_bglogo_black.svg\") no-repeat bottom right, linear-gradient(0deg, #0D0D0D, #0D0D0D), linear-gradient(0deg, rgba(33, 53, 75, 0.15), rgba(33, 53, 75, 0.15)), #111111;\n  color: #f2f3f8;\n  position: fixed;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  z-index: -1;\n}\n\n.sidebarContainer.sidebarContainer {\n  min-width: 256px;\n}\n\n.rightContent.rightContent {\n  width: 100%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bgImage": "_1VwjFBah42iN3XrGr6AKAI",
	"sidebarContainer": "_34mGUfN-RAJLjrvXpzvTE5",
	"rightContent": "RQpO_3G9W_j7kWPzxpYqK"
};
export default ___CSS_LOADER_EXPORT___;
