// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2rAkA0x3wdMSTEZJr_YXwU {\n  height: 22px;\n}\n.bvCxm-tIvllwjXoVnUT1L {\n  position: relative;\n  top: 1px;\n  text-overflow: wrap;\n  word-break: break-all;\n  color: #1f5ae2;\n}\n._3YPPU7N_dbzNMMbNdSIKtN {\n  font-family: Courier;\n}\n", "",{"version":3,"sources":["webpack://src/components/LinkBlockchain.styl"],"names":[],"mappings":"AAAA;EACE,YAAQ;AACV;AAEA;EACE,kBAAU;EACV,QAAK;EACL,mBAAe;EACf,qBAAY;EACZ,cAAO;AAAT;AAGA;EACE,oBAAa;AADf","sourcesContent":[".icon {\n  height: 22px\n}\n\n.link {\n  position: relative;\n  top: 1px;\n  text-overflow: wrap;\n  word-break: break-all;\n  color: #1F5AE2;\n}\n\n.mono {\n  font-family: Courier;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "_2rAkA0x3wdMSTEZJr_YXwU",
	"link": "bvCxm-tIvllwjXoVnUT1L",
	"mono": "_3YPPU7N_dbzNMMbNdSIKtN"
};
export default ___CSS_LOADER_EXPORT___;
